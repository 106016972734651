import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { Service } from '@/models';



@Injectable({ providedIn: 'root' })
export class ServiceService {

    constructor(private http: HttpClient) {
        
    }

    serviceInfo() {
        var service = localStorage.getItem('service')
        return this.http.get<any>(`${environment.service}api/service/`+service)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
     }

}