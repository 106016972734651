import { AbstractControl } from '@angular/forms';

export class Validacoes {

  static MatchPassword(controle: AbstractControl) {
    let novaSenha = controle.get('novaSenha').value;
    let confirmaNovaSenha = controle.get('confirmaNovaSenha').value;

    if (novaSenha === confirmaNovaSenha) return null;

    controle.get('confirmaNovaSenha').setErrors({ notMatch: true });
  }
}