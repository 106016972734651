export class Statistics {
    dia: number;
    mes: number;
    ano: number;
    qtdPacientes: number;
    qtdAutoatendimento: number;
    qtdPagamentos: number;
    qtdCheckin: number;
    PAutoatendimento: number;
    PFastPass: number;
    PQrCode: number;
}