import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { Statistics } from '@/models';

import * as $ from 'jquery';

@Injectable({ providedIn: 'root' })
export class IndicatorService {

    constructor(private http: HttpClient) {}

    indicatorInfo(startDate, endDate) {
        $('.app-loading').css('display','flex');
        return this.http.get<Statistics>(`${environment.service}api/indicator/` + startDate + '/' + endDate)
            .pipe(
                //retry(1),
                catchError(this.errorHandl)
                
            );
    }

    autoatendimentoInfo(startDate, endDate) {
      $('.app-loading').css('display','flex');
      return this.http.get<Statistics>(`${environment.service}api/indicator/autoatendimento/` + startDate + '/' + endDate)
          .pipe(
              //retry(1),
              catchError(this.errorHandl)
          );
    }

    fastpassInfo(startDate, endDate) {
      $('.app-loading').css('display','flex');
      return this.http.get<Statistics>(`${environment.service}api/indicator/fastpass/` + startDate + '/' + endDate)
          .pipe(
              //retry(1),
              catchError(this.errorHandl)
          );
    }

    qrcodeInfo(startDate, endDate) {
      $('.app-loading').css('display','flex');
      return this.http.get<Statistics>(`${environment.service}api/indicator/qrcode/` + startDate + '/' + endDate)
          .pipe(
              //retry(1),
              catchError(this.errorHandl)
          );
    }

    errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
     }

}