import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, HostListener } from '@angular/core';

import { User, Statistics, Graph } from '@/models';
import { ServiceService,AuthenticationService, PeriodService, IndicatorService } from '@/services';
import { Subscription } from 'rxjs';

import * as $ from 'jquery';

@Component({ templateUrl: 'home.component.html'})
export class HomeComponent implements OnInit {
    currentUser: User;
    users = [];

    startDateSubscription: Subscription;
    endDateSubscription: Subscription;
    startDate: string;
    endDate: string;

    public total: Statistics;
    public show: Statistics;

    graphAutoatendimento: Graph[] = [];
    graphFastPass: Graph[] = [];
    graphQrCode: Graph[] = [];

    dataSourceAutoatendimento: Object;
    dataSourceFastPass: Object;
    dataSourceQrCode: Object;
    chartConfig: Object;

    constructor(
        private authenticationService: AuthenticationService,
        private serviceService: ServiceService,
        private periodService: PeriodService,
        private indicatorService: IndicatorService,
        private ref: ChangeDetectorRef
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.show = new Statistics();

        this.chartConfig = {
            width: '100%',
            height: '400',
            type: 'line',
            dataFormat: 'json',
        };

    }

    ngOnInit() {
        $('.app-loading').css('display','flex');
        this.startDateSubscription = this.periodService.obterStartDate()
        .subscribe(valor => {
            this.startDate = valor;
            
        });

        this.endDateSubscription = this.periodService.obterEndDate()
        .subscribe(valor => {
            this.endDate = valor;
            this.indicatorInfo();
            this.autoatendimentoInfo();
            this.fastpassInfo();
            this.qrcodeInfo();
        });
    }

    indicatorInfo(){
        this.indicatorService.indicatorInfo(this.startDate,this.endDate)
        .subscribe(data => {
            this.total = data;
            this.updateValues();
          });
    }

    updateValues(){
        this.show.dia = this.total.dia;
        this.show.mes = this.total.mes;
        this.show.ano = this.total.ano;
        this.show.qtdPacientes = this.total.qtdPacientes;
        this.show.qtdAutoatendimento = this.total.qtdAutoatendimento;
        this.show.qtdCheckin = this.total.qtdCheckin;
        this.show.qtdPagamentos = this.total.qtdPagamentos;
        this.show.PAutoatendimento = !isNaN((this.total.qtdAutoatendimento / this.total.qtdPacientes) * 100) ? Math.round(((this.total.qtdAutoatendimento / this.total.qtdPacientes) * 100)) : 0;
        this.show.PFastPass = !isNaN((this.total.qtdPagamentos / this.total.qtdAutoatendimento) * 100) ? Math.round(((this.total.qtdPagamentos / this.total.qtdAutoatendimento) * 100)) : 0;
        this.show.PQrCode = !isNaN((this.total.qtdCheckin / this.total.qtdPacientes) * 100) ? Math.round(((this.total.qtdCheckin / this.total.qtdPacientes) * 100)) : 0;

        $('.app-loading').css('display','none');
        this.ref.detectChanges();
    }

    autoatendimentoInfo(){
        this.indicatorService.autoatendimentoInfo(this.startDate,this.endDate)
        .subscribe(data => {
            this.buildAutoatendimentoGraph(data);
          });
    }

    fastpassInfo(){
        this.indicatorService.fastpassInfo(this.startDate,this.endDate)
        .subscribe(data => {
            this.buildFastPassGraph(data);
          });
    }

    qrcodeInfo(){
        this.indicatorService.qrcodeInfo(this.startDate,this.endDate)
        .subscribe(data => {
            this.buildQrCodeGraph(data);
          });
    }

    buildAutoatendimentoGraph(data){

        this.graphAutoatendimento = [];
        for(let element of data){
            if(!isNaN((element.qtdAutoatendimento / element.qtdPacientes) * 100)){
                this.graphAutoatendimento.push({'label': (element.dia != 0 ? (element.dia + "/") : '') + element.mes + "/" + element.ano, 'value': ((element.qtdAutoatendimento / element.qtdPacientes) * 100)});
            }
            console.log(element);
        }

        console.log(this.graphAutoatendimento);

        this.dataSourceAutoatendimento = {
            "chart": {
              "caption": "Autoatendimentos",
              "subCaption": "",
              "xAxisName": "Meses",
              "yAxisName": "Taxa de Aderência",
              "numberSuffix": "%",
              "theme": "fusion",
              "yAxisMaxValue": "100",
              "yAxisMinValue": "0"
            },
            "data": this.graphAutoatendimento
          };
    }

    buildFastPassGraph(data){

        this.graphFastPass = [];
        for(let element of data){
            if(!isNaN((element.qtdPagamentos / element.qtdAutoatendimento) * 100)){
                this.graphFastPass.push({'label': (element.dia != 0 ? (element.dia + "/") : '')  + element.mes + "/" + element.ano, 'value': ((element.qtdPagamentos / element.qtdAutoatendimento) * 100)});
            }
        }

        this.dataSourceFastPass = {
            "chart": {
              "caption": "Fast Pass",
              "subCaption": "",
              "xAxisName": "Meses",
              "yAxisName": "Taxa de Aderência",
              "numberSuffix": "%",
              "theme": "fusion",
              "yAxisMaxValue": "100",
              "yAxisMinValue": "0"
            },
            "data": this.graphFastPass
          };
    }

    buildQrCodeGraph(data){

        this.graphQrCode = [];
        for(let element of data){
            if(!isNaN((element.qtdCheckin / element.qtdPacientes) * 100)){
                this.graphQrCode.push({'label': (element.dia != 0 ? (element.dia + "/") : '')  + element.mes + "/" + element.ano, 'value': ((element.qtdCheckin / element.qtdPacientes) * 100)});
            }
        }

        this.dataSourceQrCode = {
            "chart": {
              "caption": "QR Code",
              "subCaption": "",
              "xAxisName": "Meses",
              "yAxisName": "Taxa de Aderência",
              "numberSuffix": "%",
              "theme": "fusion",
              "yAxisMaxValue": "100",
              "yAxisMinValue": "0"
            },
            "data": this.graphQrCode
          };
    }

    ngOnDestroy() {
        if (this.startDateSubscription)
          this.startDateSubscription.unsubscribe();
    
        if (this.endDateSubscription)
          this.endDateSubscription.unsubscribe();
      }
}