import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';



@Injectable({ providedIn: 'root' })
export class PeriodService {

    private startDateBehaviorSubject = new BehaviorSubject<string>('');
    private endDateBehaviorSubject = new BehaviorSubject<string>('');

    constructor() { }
  
    alterarData(start: string, end: string) {
      this.startDateBehaviorSubject.next(start);
      this.endDateBehaviorSubject.next(end);      
    }
  
    obterStartDate() {
        return this.startDateBehaviorSubject;
    }

    obterEndDate() {
        return this.endDateBehaviorSubject;
    }

    
}