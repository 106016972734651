import { Component, OnInit, Pipe, PipeTransform, ViewChild, Input  } from '@angular/core';
import { Period, Service } from '@/models';
import { formatDate } from '@angular/common';
import { ServiceService, ModalService, PeriodService } from '@/services';
import { DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.scss']
})
export class PeriodComponent implements OnInit {
  @ViewChild("rangeObj",null) rangeObj: DateRangePickerComponent;  

  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  public dateValue: Object = new Date(new Date().setDate(14));
  public startDP: Date = new Date(this.currentYear, this.currentMonth, 1);
  public endDP: Date = new Date(this.currentYear, this.currentMonth+1, 0);

  public startDateJSON: string;
  public endDateJSON: string;
  public startDate: Date;
  public endDate: Date;

  selectedPeriodS:string;
  selectedTitle;
  selectedService: Service;
  periods:Array<Period> = [
    {
        id: 1,
        value: '0',
        label: 'Ontem',
        start: new Date(Date.now() - 864e5).toJSON(),
        end: new Date(Date.now() - 864e5).toJSON(),
        title: formatDate(new Date(Date.now() - 864e5),'dd/MM/yyyy','pt')
    },
    {
        id: 2,
        value: '1',
        label: 'Hoje',
        start: new Date().toJSON(),
        end: new Date().toJSON(),
        title: formatDate(new Date(),'dd/MM/yyyy','pt')
    },
    {
        id: 3,
        value: '2',
        label: 'Semana',
        start: this.startOfWeek(new Date()).toJSON(),
        end: new Date().toJSON(),
        title: formatDate(new Date(),"'Seg - 'EE",'pt')
    },
    {
        id: 4,
        value: '3',
        label: 'Mês',
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toJSON(),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toJSON(),
        title: formatDate(new Date(),"MMMM 'de' yyyy",'pt')

    },
    {
        id: 5,
        value: '4',
        label: 'Ano',
        start: new Date(new Date().getFullYear(), 0, 1).toJSON(),
        end: new Date(new Date().getFullYear(), 11, 31).toJSON(),
        title: formatDate(new Date(),'yyyy','pt')
    },
    {
        id: 6,
        value: '5',
        label: 'Custom',
        start: this.startDateJSON,
        end: this.endDateJSON,
        title: ''
    }
];

  constructor(
    private serviceService: ServiceService, private modalService: ModalService, private periodService: PeriodService) {
    }

  ngOnInit() {
    this.selectedPeriodS = '1';
    this.changeTitle(this.selectedPeriodS);
    this.getServiceInfo();
    this.startDateJSON = this.periods[this.selectedPeriodS].start;
    this.endDateJSON = this.periods[this.selectedPeriodS].end;
    this.enviarInput();
  }

  getServiceInfo(){
    this.serviceService.serviceInfo()
      .subscribe(data => {
        this.selectedService = data;
      });
  }

  changePeriod(){
    if(this.selectedPeriodS != '5'){
      this.changeTitle(this.selectedPeriodS);
      this.startDateJSON = this.periods[this.selectedPeriodS].start;
      this.endDateJSON = this.periods[this.selectedPeriodS].end;
      this.enviarInput();
      this.close();
    }
    
  }

  changeCustomPeriod(){
    this.modalService.open('customPeriodModal');
  }

  closeModal() {
    this.changeCustom();
    this.modalService.close('customPeriodModal');
  }

  close() {
    this.modalService.close('customPeriodModal');
  }

  changeCustom(){
    this.startDateJSON = this.rangeObj.getSelectedRange()['startDate'].toJSON();
    this.endDateJSON = this.rangeObj.getSelectedRange()['endDate'].toJSON();
    this.startDate = this.rangeObj.getSelectedRange()['startDate'];
    this.endDate = this.rangeObj.getSelectedRange()['endDate'];
    this.periods[5].title = formatDate(this.startDate,'dd/MM/yyyy','pt') + ' - ' + formatDate(this.endDate,'dd/MM/yyyy','pt');
    this.changeTitle('5');
    this.enviarInput();
    this.close();
  }

  enviarInput(){
    this.periodService.alterarData(this.startDateJSON.split('T')[0], this.endDateJSON.split('T')[0]);
  }
  
  changeTitle(periodS){
    this.selectedTitle = (this.periods).filter(function(periods) {
      return periods.value == periodS;
    });
  }

  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

}
