import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularMaterialModule } from './library/angular-material.module';

import { appRoutingModule } from './app.routing';
import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { AppComponent } from './app.component';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { AlertComponent, ModalComponent } from './components';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PeriodComponent } from './period/period.component';

import { registerLocaleData } from '@angular/common';
import localept from '@angular/common/locales/pt';
import { ChangePasswordComponent } from './change-password/change-password.component';

import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FusionChartsModule } from 'angular-fusioncharts';

// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

registerLocaleData(localept, 'pt');

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        appRoutingModule,
        AngularMaterialModule,
        BrowserAnimationsModule,
        FormsModule,  
        DateRangePickerModule,
        FlexLayoutModule,
        FusionChartsModule
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        AlertComponent,
        ForgotPasswordComponent,
        PeriodComponent,
        ChangePasswordComponent,
        ModalComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'pt' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };