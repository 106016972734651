import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AlertService, AuthenticationService } from '@/services';
import { first } from 'rxjs/operators';

@Component({ templateUrl: './forgot-password.component.html'})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  loading = false;
  submitted = false;
  currentUrl = window.location.href;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) {
      if (this.authenticationService.currentUserValue) {
          this.router.navigate(['/']);
      }
  }

  back(){
    this.router.navigate(['/login']);
  }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
        email: ['', Validators.required]
    });
}

get f() { return this.forgotForm.controls; }

onSubmit() {
    this.submitted = true;
    this.alertService.clear();
    if (this.forgotForm.invalid) {
        return;
    }

    this.loading = true;
    this.authenticationService.forgotPassword(this.f.email.value, this.currentUrl)
        .pipe(first())
        .subscribe(
            data => {
              if(data == null){
                this.alertService.error("Email inválido!");
                this.loading = false;
              }else{
                this.router.navigate(['/login']);
                this.alertService.success("Email enviado com sucesso!");
              }
            });
}

}
