import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '@/models';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email, password, serviceAlias) {
        var software = "FASTCHECKIN";
        return this.http.post<any>(`${environment.auth}api/account/login`, { email, password, serviceAlias, software })
            .pipe(map(user => {
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    forgotPassword(email, link){
        return this.http.post<any>(`${environment.service}api/user/forgotPassword`, { email, link })
            .pipe(map(user => {
                return user;
            }));
    }

    changePassword(UserId, Token, Password, ConfirmPassword){
        return this.http.post<any>(`${environment.auth}api/account/confirmResetPassword/`, { UserId, Token, Password, ConfirmPassword })
            .pipe(map(user => {
                return user;
            }));
    }

    logout() {
        localStorage.clear();
        this.currentUserSubject.next(null);
    }
}