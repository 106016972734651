import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AlertService, AuthenticationService } from '@/services';
import { first } from 'rxjs/operators';
import { Validacoes } from '@/helpers/validacoes';

@Component({ templateUrl: './change-password.component.html'})
export class ChangePasswordComponent implements OnInit {
  changeForm: FormGroup;
  loading = false;
  submitted = false;

  UserId = "";
  Token = "";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) {
      if (this.authenticationService.currentUserValue) {
          this.router.navigate(['/']);
      }
  }

  ngOnInit() {
    this.changeForm = this.formBuilder.group({
        novaSenha: ['', Validators.required],
        confirmaNovaSenha: ['', Validators.compose([Validators.required])]
    },
    {
      validator: Validacoes.MatchPassword
    });

    this.route.queryParamMap.subscribe(params => {
      this.UserId = this.route.snapshot.queryParamMap.get('u');
      this.Token = this.route.snapshot.queryParamMap.get('t');
    });
}

get f() { return this.changeForm.controls; }

get senha() {
  return this.changeForm.get('novaSenha');
}

get confirmarSenha() {
  return this.changeForm.get('confirmaNovaSenha');
}

back(){
  this.router.navigate(['/login']);
}

onSubmit() {
    this.submitted = true;
    this.alertService.clear();
    if (this.changeForm.invalid) {
        return;
    }



    this.loading = true;
    this.authenticationService.changePassword(this.UserId, this.Token, this.f.novaSenha.value, this.f.confirmaNovaSenha.value)
        .pipe(first())
        .subscribe(
            data => {
              if(data == null){
                this.alertService.error("Ocorreu um erro!");
                this.loading = false;
              }else{
                this.router.navigate(['/login']);
                this.alertService.success("Senha alterada com sucesso!");
              }
            });
}

}
