import { Component,HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './services';
import { User } from './models';

import * as $ from 'jquery';

import { loadCldr, L10n, setCulture } from '@syncfusion/ej2-base';

import * as numberingSystems from '../../node_modules/cldr-data/supplemental/numberingSystems.json';
import * as gregorian from '../../node_modules/cldr-data/main/pt/ca-gregorian.json';
import * as numbers from '../../node_modules/cldr-data/main/pt/numbers.json';
import * as pttimeZoneNames from '../../node_modules/cldr-data/main/pt/timeZoneNames.json';
import * as weekdata from '../../node_modules/cldr-data/supplemental/weekdata.json';

loadCldr(numberingSystems, gregorian, numbers, pttimeZoneNames, weekdata);

L10n.load({
    'pt': {
        'daterangepicker': {  
            placeholder: 'Selecione um período',
            startLabel: 'Data Inicial',
            endLabel: 'Data Final',
            applyText: 'Aplicar',
            cancelText: 'Cancelar',
            selectedDays: 'Dias Selecionados',
            days: 'Dias',
            customRange: ''
            }
        }
    });
setCulture('pt');

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent implements OnDestroy {
    currentUser: User;

    public menuItems:Array<Object> = [
        {name: 'Dashboard', icon: 'show_chart', link: '/'},
        {name: 'Lista', icon: 'list_alt', link: '', click: ''},
        //{name: 'Ranking', icon: 'equalizer', link: '', click: ''},
    ];

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    ngOnInit() {
        $('#router-pages').appendTo("#main-content");
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    @HostListener('window:unload', ['$event'])
    unloadHandler(event) {
        //this.authenticationService.logout();
        //this.router.navigate(['/login']);
    }

    ngOnDestroy(){
        //this.authenticationService.logout();
        //this.router.navigate(['/login']);
    }

}